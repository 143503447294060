import { Model } from '../core/base.model';

import { SimpleEntityActions } from '@app/classes';

export class Note extends Model {
    get authorId(): number {
        return this._attributes['authorId'];
    }
    set authorId(val: number) {
        this._attributes['authorId'] = val;
    }

    get title(): string {
        return this._attributes['title'];
    }
    set title(val: string) {
        this._attributes['title'] = val;
    }

    get body(): string {
        return this._attributes['body'];
    }
    set body(val: string) {
        this._attributes['body'] = val;
    }

    set entityType(val: string) {
        this._attributes['entityType'] = val;
    }
    set entityId(val: number) {
        this._attributes['entityId'] = val;
    }
    protected static _resource = 'commonComponents/notes';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['title', 'body', 'authorId', 'entityType', 'entityId'];

    static permission = new SimpleEntityActions('note');
}
