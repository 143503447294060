import { Model } from '../core/base.model';

import { SimpleEntityActions } from '@app/classes';

export class Journey extends Model {
    static permission = new SimpleEntityActions('employeeJourney');

    get field(): string {
        return this._attributes['field'];
    }
    get oldValue(): string {
        return this._attributes['old_value'];
    }
    get newValue(): string {
        return this._attributes['new_value'];
    }
    get effectiveDate(): Date {
        return this._attributes['effective_date'];
    }
    set field(val: string) {
        this._attributes['field'] = val;
    }
    set oldValue(val: string) {
        this._attributes['old_value'] = val;
    }
    set newValue(val: string) {
        this._attributes['new_value'] = val;
    }
    set effectiveDate(val: Date) {
        this._attributes['effective_date'] = val;
    }
}
