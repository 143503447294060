import { SimpleEntityActions } from '@app/classes';
import { Model } from '../core/base.model';
import { DataField } from '../settings/data-field.model';
import { Employee } from './employee.model';
import { DataFieldOption } from '../settings/data-field-option.model';

export class DataValue extends Model {
    static permission = new SimpleEntityActions('employeeDataValue');

    protected static _resource = 'companyManagement/companies/:company/dataValues';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['employeeId', 'dataFieldId', 'dataFieldOptionId', 'value'];

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get employee(): DataField {
        return this.hasOne(Employee, 'employee');
    }

    get dataFieldId(): number {
        return this._attributes['dataFieldId'];
    }
    set dataFieldId(val: number) {
        this._attributes['dataFieldId'] = val;
    }

    get value(): string {
        return this._attributes['value'];
    }

    /*
     * If you try to set a value and the field is a dropdown, the backend will
     * ignore the value and it will not update. The value for dropdown fields are
     * read only!
     *
     * If you're trying to change the value of a dropdown data value
     * then you will need to update the dataFieldOptionId.
     * */
    set value(val: string) {
        this._attributes['value'] = val;
    }

    /*
     * Display Value is the formatted value being returned from the back-end,
     * you cannot update this value directly since it's derived from the value
     * */
    get displayValue(): string | null {
        return this._attributes['displayValue'];
    }

    get dataFieldOptionId(): string {
        return this._attributes['dataFieldOptionId'];
    }
    set dataFieldOptionId(val: string) {
        this._attributes['dataFieldOptionId'] = val;
    }

    get dataFieldOption(): DataFieldOption {
        return this.hasOne(DataFieldOption, 'dataFieldOption');
    }

    get dataField(): DataField {
        return this.hasOne(DataField, 'dataField');
    }

    get canEdit(): boolean {
        return this._attributes['canEdit'];
    }
}
