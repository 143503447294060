export enum ProfileTabs {
    personal = 'personal',
    jobAndPay = 'job-and-pay',
    reminders = 'reminders',
    records = 'records',
    journey = 'journey',
    timeOff = 'time-off',
    benefits = 'benefits',
    performance = 'performance',
    survey = 'survey',
}
